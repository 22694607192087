export const ERROR_TYPES = {
  GET_ERRORS: "GET_ERRORS",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

const initialState = {};

export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case ERROR_TYPES.GET_ERRORS:
      if (
        typeof action.payload === "string" &&
        action.payload === "Unauthorized"
      ) {
        return { other: "Votre session a expiré. Veuillez vous reconnecter" };
      }
      return action.payload;
      break;
    case ERROR_TYPES.CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
}
