import { useEffect } from "react";

const thumbnails = {
  large: [
    "/front/hero/large/thumb/bg_home_sm.webp",
    "/front/hero/large/thumb/bg_ac_sm.webp",
    "/front/hero/large/thumb/bg_mrp_sm.webp",
    "/front/hero/large/thumb/bg_pj_sm.webp",
    "/front/hero/large/thumb/bg_cyber_sm.webp",
    "/front/hero/large/thumb/bg_domi_sm.webp",
  ],
  small: [
    "/front/hero/small/thumb/bg_m_home_sm.webp",
    "/front/hero/small/thumb/bg_m_ac_sm.webp",
    "/front/hero/small/thumb/bg_m_mrp_sm.webp",
    "/front/hero/small/thumb/bg_m_pj_sm.webp",
    "/front/hero/small/thumb/bg_m_cyber_sm.webp",
    "/front/hero/small/thumb/bg_m_domi_sm.webp",
  ],
};

const ThumbnailPreloader = () => {
  useEffect(() => {
    const preloadImages = (imageArray) => {
      imageArray.forEach((imageUrl) => {
        const img = new Image();
        img.src = imageUrl;
      });
    };

    if (window.innerWidth > 768) {
      preloadImages(thumbnails.large);
    } else {
      preloadImages(thumbnails.small);
    }
  }, []);

  return null; // This component does not render anything
};

export default ThumbnailPreloader;
