import { toast } from "react-toastify";
import { ERROR_TYPES } from "../context/reducers/errorReducers";
import axios from "axios";
import { DateTime } from "luxon";
import Geocode from "react-geocode";

const tools = {
  setAuthToken: (token) => {
    if (token) {
      // Apply to every request
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      // Delete Auth header
      delete axios.defaults.headers.common["Authorization"];
    }
  },
  debounce: (fn, ms) => {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  },
  round: (value) => {
    if (!value) return 0;
    return Math.round(parseFloat(value) * 100) / 100;
  },
  isEmpty: (value) => {
    return (
      value === undefined ||
      value === null ||
      !value ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  },

  truncate: (text, n) => {
    return text.length > n ? text.substr(0, n - 1) + "..." : text;
  },

  filterError: (errors, dispatch = null, type) => {
    var errorData = {};
    if (errors.response) {
      var status = errors.response.status;

      if (status == 404) {
        toast.error("La route n'a pas été trouvée");
      }

      if (errors.response.data) {
        if (errors.response.data.other) {
          toast.warning("Erreur : " + errors.response.data.other);
          errorData = errors.response.data;
        } else {
          errorData = errors.response.data;
        }
      }
    } else {
      toast.error("Erreur interne : " + errors);
    }

    if (dispatch) {
      dispatch({ type: type, payload: false });
      dispatch({ type: ERROR_TYPES.GET_ERRORS, payload: errorData });
    }
    return errorData;
  },
  filterAlert: (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "info":
        toast.info(message);
        break;

      default:
        break;
    }
  },
  getState: (page, initial) => {
    var ls = localStorage.getItem(page);

    if (ls) {
      return JSON.parse(ls);
    }
    return initial;
  },

  saveState: (page, name, value) => {
    var ls = localStorage.getItem(page);
    if (ls) {
      ls = JSON.parse(ls);
    } else {
      ls = {};
    }
    ls[name] = value;

    localStorage.setItem(page, JSON.stringify(ls));
  },

  isParentRoleActive(roleKey, roleConfig, dependsOn) {
    if (!dependsOn) {
      return true;
    }
    var isActive = true;
    dependsOn.forEach((d) => {
      if (!roleConfig[d][roleKey]) {
        isActive = false;
      }
    });
    return isActive;
  },
  replaceAll: (string, search, replace) => {
    return string.split(search).join(replace);
  },
  numberWithSpaces: (x) => {
    x = (x + "").replace(/\s/g, "");
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    x = x.split(".");
    if (x[1] && x[1].length == 1) {
      x[1] = x[1] + "0";
    }
    return x.join(".");
  },
  truenumberWithSpaces: (x) => {
    x = x == "0" ? "" : x + "";
    x = x.replace(/\s/g, "");
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },
  numberWithTrailingZero: (x) => {
    var y = x.split(",");
    if (y[1] && y[1].length < 2) {
      y[1] = y[1] + 0;
    }
    return y.join(",");
  },
  formatFollowingPattern: (value, separation, separator) => {
    var nirconcat = value.split(separator).join("");

    var newnir = "";
    var curr = 0;
    for (var key in separation) {
      var ns = separation[key] + curr;
      if (curr < nirconcat.length) {
        newnir += nirconcat.substring(curr, ns);
        var newnirsp = newnir.split(separator);
        if (
          newnirsp[newnirsp.length - 1].length == separation[key] &&
          newnirsp.length < separation.length
        ) {
          newnir += separator;
        }
      }

      curr = ns;
    }
    if (newnir.substr(newnir.length - separator.length) == separator) {
      newnir = newnir.substring(0, newnir.length - separator.length);
    }
    return newnir;
  },
  formatAnswersToTarif: (datas) => {
    var formattedDatas = [];

    var spCp = ["973", "976"];

    datas.products.forEach((prod) => {
      if (!datas[prod]) return false;
      var cp = datas.postalCode.substring(0, 3);
      var postalCode = spCp.includes(cp) ? "973" : "974";

      formattedDatas.push({
        ...datas[prod],
        productType: prod,
        postalCode: postalCode,
        ape: datas.ape,
        turnover: parseInt(datas.turnover.replace(/ /g, "")),
        endAt: DateTime.fromISO(datas[prod].beginAt).plus({ year: 1 }).startOf("day").toISO(),
      });
    });
    return formattedDatas;
  },
  formatNumber2Decimals: (num) => {
    num = num + "";
    num = num.split(".");
    if (num[1] && num[1].length > 0) {
      num[1] = num[1].length == 1 ? num[1] + "0" : num[1];
    }
    return num.join(".");
  },
  formatAnswersForApi: (ans, step) => {
    var spCp = ["973", "976"];
    var cp = ans.postalCode.substring(0, 3);
    var postalCode = spCp.includes(cp) ? "973" : "974";

    var result = {
      step: parseInt(step) + 1,
      quotationId: ans.quotationId,
      paymentDatas: {},
      clientDatas: {
        id: ans.clientId == "" ? null : ans.clientId,
        user: ans.id == "" ? null : ans.id,
        company: ans.company,
        gender: ans.gender,
        lastname: ans.lastname,
        firstname: ans.firstname,
        email: ans.email,
        mobilePhone: ans.phone,
        ape: ans.ape,
        legalForm: ans.legalForm == "" ? null : ans.legalForm,
        siret: ans.siret,
        turnover: parseInt(ans.turnover.replace(/ /g, "")),
        workforce: ans.workforce,
        registrationAt: ans.registrationAt == "" ? null : ans.registrationAt,
        companyActivity: ans.companyActivity == "" ? null : ans.companyActivity,
        location1: {
          address: ans.address1,
          address2: ans.address2,
          postalCode: ans.postalCode,
          city: ans.city,
          country: "",
        },
        constructionActivity: ans.activity,
        alreadyInsured: ans.alreadyInsured,
        comment: ans.comment,
        turnoverOutsourced: ans.turnoverOutsourced,
      },
      products: ans.products.map((p) => {
        return {
          ...ans[p],
          productType: p,
          postalCode: postalCode,
          ape: ans.ape,
          turnover: parseInt(ans.turnover.replace(/ /g, "")),
          endAt: DateTime.fromISO(ans[p].beginAt).plus({ year: 1 }).startOf("day").toISO(),
        };
      }),
    };

    if (ans.products.find((f) => f == "PJ")) {
      result.paymentDatas = {
        paymentType: ans.Payment.modeP,
        paymentIntentId: ans.Payment.paymentIntentId,
      };
    }

    return result;
  },
  buildAnswersObject: (form) => {
    const multipleValuesTypes = ["checkBtnList", "checkboxList"];
    const booleanValuesTypes = ["checkbox"];
    var ans = {};
    form.forEach((qs) => {
      qs.questions.forEach((q) => {
        var inpValue = multipleValuesTypes.includes(q.type)
          ? typeof q.defaultValue !== "undefined"
            ? q.defaultValue
            : []
          : booleanValuesTypes.includes(q.type)
          ? false
          : typeof q.defaultValue !== "undefined"
          ? q.defaultValue
          : "";
        if (q.type == "info") return false;
        if (q.partOfSubObject) {
          ans[q.partOfSubObject] = ans[q.partOfSubObject] ? ans[q.partOfSubObject] : {};
          ans[q.partOfSubObject][q.name] = inpValue;
        }
        if (q.type == "component") {
          if (q.state) {
            var entryName = q.stateName ? q.stateName : q.name;
            ans[entryName] = ans[entryName] ? ans[entryName] : {};
            q.state.forEach((s) => {
              ans[entryName][s.name] = multipleValuesTypes.includes(s.type)
                ? s.defaultValue
                  ? s.defaultValue
                  : []
                : booleanValuesTypes.includes(s.type)
                ? false
                : s.defaultValue
                ? s.defaultValue
                : "";
            });
          }
        } else {
          ans[q.name] = inpValue;
        }
      });
    });
    return ans;
  },
  getPosFromAddress: (address) => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
    return new Promise((resolve, reject) => {
      Geocode.fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          resolve({ latitude: lat, longitude: lng });
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  getFullname: (person) => {
    return person
      ? (person.gender == 1 ? "Mr" : "Mme") + " " + person.firstname + " " + person.lastname
      : "";
  },
  isOwner: (qualityOccupant) => {
    return qualityOccupant == 1 || qualityOccupant == 2;
  },
  calculateContentValue: (sumArray) => {
    return sumArray.reduce((a, b) => a + b);
  },
  formatSiretReduced: (siret) => {
    var res = siret + "";
    return res.slice(0, 3) + "..." + res.slice(-5);
  },
  calcPct: (v1, v2) => {
    var res = (v1 / v2) * 100;
    return res;
  },
  downloadBlob: (blob, fileName) => {
    const link = document.createElement("a");
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  },
};

export default tools;
