import "./animations.scss";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import { useEffect } from "react";
import { useRouter } from "next/router";
import Tracker from "@openreplay/tracker/cjs";
import tools from "../helpers/tools";
import Script from "next/script";
import ThumbnailPreloader from "../components/ThumbnailPreloader";

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      document.querySelector("body").scrollTo(0, 0);
    };
    router.events.on("routeChangeStart", handleRouteChange);
    //tawkto("5b6830dadf040c3e9e0c536a");

    window.tracker = new Tracker({
      projectKey: process.env.OPENREPLAY_KEY,
      onStart: ({ sessionID }) =>
        console.log("OpenReplay tracker started with session: ", sessionID),
    });
    window.tracker.start();

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  if (router.query.afcode) {
    tools.saveState("ALL", "afcode", router.query.afcode);
  }

  return (
    <>
      <Component key={"main-comp"} {...pageProps} />
      {process.env.NODE_ENV === "production" && (
        <>
          <noscript key={"gtm"}>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-N5X7GQB"
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>
          <Script src="https://cache.consentframework.com/js/pa/32260/c/SaftS/stub" />
          <Script src="https://tag.analytics-helper.com/ans/ga?pa=32260&c=436&tid=G-HS8QB9DQMF%2CUA-108485883-5" />
          <Script src="https://choices.consentframework.com/js/pa/32260/c/SaftS/cmp" />
        </>
      )}
      <ThumbnailPreloader />
    </>
  );
}
